export enum SortOrderEnum {
  ASC = "asc",
  DESC = "desc",
}

export interface PaginationQueryParams {
  skip?: number;
  take?: number;
  orderBy?: { [key: string]: SortOrderEnum };
}
