import {
  CSSProperties,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import dynamic from "next/dynamic";
import { fileControllerGetSignedUrl } from "@shared/generated/api/fn/kac/file/file";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import ReactQuill, { DeltaStatic } from "react-quill-new";
import { Box, Button, CircularProgress } from "@mui/material";
import { FileControllerGetSignedUrlParams } from "@shared/generated/api/model";
import { uploadFileToGCP } from "@/components/common/features/cloud-storage/upload-file";
import styled from "@emotion/styled";
import "react-quill-new/dist/quill.snow.css";

interface EditorProps {
  placeholder: string;
  onEditorContent: (content: DeltaStatic) => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitDisabled?: boolean;
  showSubmitButton?: boolean;
  initialContent?: DeltaStatic;
  isEditing?: boolean;
  readOnly?: boolean; // 읽기 전용 모드 추가
  showToolbar?: boolean; // 툴바 표시 여부
  height?: string;
}

interface CustomReactQuillProps {
  forwardedRef: RefObject<ReactQuill>;
  modules: any;
  formats: string[];
  value: any;
  onChange: (content: any) => void;
  theme?: string;
  placeholder?: string;
  style?: CSSProperties;
  readOnly?: boolean;
}

const CustomReactQuill = dynamic(
  async () => {
    const { default: RQ } = await import("react-quill-new");
    const Quill = ({ forwardedRef, ...props }: CustomReactQuillProps) => (
      <RQ
        ref={forwardedRef}
        {...props}
      />
    );
    return Quill;
  },
  { ssr: false },
);

export const BasicEditor = ({
  onEditorContent,
  onSubmit,
  onCancel,
  submitDisabled = false,
  showSubmitButton = false,
  placeholder = "",
  initialContent,
  isEditing = false,
  readOnly = false,
  showToolbar = true,
}: EditorProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const [content, setContent] = useState<DeltaStatic | null>(
    initialContent ?? null,
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (content) {
      onEditorContent(content);
    }
  }, [content, onEditorContent]);

  const handleChange = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const delta = editor.getContents(); // Delta 형식으로 가져오기
      setContent(delta); // Delta 형식으로 상태 업데이트
    }
  };

  const { accessToken } = useAccessToken();
  const handleImageUpload = () => {
    if (!accessToken) {
      alert(
        "인증 정보가 유효하지 않습니다. 페이지를 새로고침하거나 다시 로그인해주세요.",
      );
      return;
    }

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0];
      setLoading(true);
      if (!file) return;
      console.log(input.files);
      console.log("Selected file:", file);
      console.log("Access token:", accessToken);
      try {
        const params: FileControllerGetSignedUrlParams = {
          scope: "public",
          originalFilename: file.name,
          resourceType: "courses",
          fileType: "image",
        };
        // 1. 백엔드에서 presigned URL 요청
        const { url, filePath } = await fileControllerGetSignedUrl(params, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        // 2. presigned URL을 사용하여 GCP에 이미지 업로드
        const status = await uploadFileToGCP(url as string, file);
        console.log(status);
        // 3. 업로드된 이미지 파일명 추출
        const pathArr = filePath.split("/");
        const fileName = pathArr[pathArr.length - 1];
        if (status === 200) insertImage(fileName);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false);
      }
      setLoading(false);
    };
  };

  const insertImage = (fileName: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_STORAGE_HOST;

    const imageUrl = `${baseUrl}/courses/image/${fileName}`;
    const editor = quillRef?.current?.getEditor();
    const range = editor?.getSelection();

    if (range) {
      editor?.insertEmbed(range.index, "image", imageUrl);
    } else {
      editor?.insertEmbed(0, "image", imageUrl);
    }
    setContent(editor?.getContents() || null);
    setLoading(false);
  };

  const modules = useMemo(
    () => ({
      toolbar: showToolbar
        ? {
            container: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link", "image", "video"],
            ],
            handlers: {
              image: handleImageUpload,
            },
          }
        : false,
    }),
    [showToolbar],
  );

  const formats = useMemo(
    () => [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "link",
      "image",
      "video",
    ],
    [],
  );

  return (
    <EditorContainer readOnly={readOnly}>
      <CustomReactQuill
        forwardedRef={quillRef}
        modules={modules}
        formats={formats}
        value={content}
        onChange={handleChange}
        // theme={readOnly ? "bubble" : "snow"}
        theme="snow"
        placeholder={placeholder}
        style={{ display: "flex", flexDirection: "column" }}
        readOnly={readOnly}
      />
      {showSubmitButton && (
        <ButtonContainer>
          {isEditing && (
            <Button
              variant="outlined"
              onClick={onCancel}
              sx={{ mr: 2, mb: 2 }}
            >
              취소
            </Button>
          )}
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={submitDisabled}
            sx={{ mr: 2, mb: 2 }}
          >
            등록
          </Button>
        </ButtonContainer>
      )}
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <CircularProgress size="5rem" />
        </Box>
      )}
    </EditorContainer>
  );
};

const EditorContainer = styled.div<{ readOnly?: boolean }>`
  border: ${({ readOnly }) => (readOnly ? "none" : "1px solid #d2d2d2")};
  border-radius: ${({ readOnly }) => (readOnly ? "0" : "8px")};
  width: 100%;

  .quill {
    border: none;
    min-height: 344px;
    width: 100%;
  }

  .ql-container {
    border: none !important;
  }

  .ql-container .ql-editor {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ql-container .ql-editor p {
    margin: 0; // 기본 마진 제거
    width: 100%; // 전체 너비 사용
  }

  .ql-toolbar {
    display: ${({ readOnly }) =>
      readOnly ? "none" : "block"}; // readOnly일 때 toolbar 숨김
    border: none;
  }

  .ql-toolbar.ql-snow {
    border-width: 0 0 1px 0;
    border-bottom: 1px solid #d2d2d2;
  }

  // 포커스 시 placeholder 숨김 처리
  .ql-editor:focus.ql-blank::before {
    display: none;
  }

  /* 읽기 전용일 때의 스타일 */
  ${({ readOnly }) =>
    readOnly &&
    `
    .ql-editor {
      padding: 16px 0;
      gap: 16px;
      
      &:not(.ql-blank)::before {
        display: none;
      }
    }
      
    .ql-container {
      border: none;
    }

    .ql-editor p {
      margin: 0;
    }

    .ql-editor img {
      max-width: 100%;
    }
  `}

  .ql-tooltip {
    transform: translate(50%, 10%);
  }

  img,
  video {
    width: 100%;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
`;

const ButtonContainer = styled.div`
  padding: 0;
  gap: 0px;
  border-radius: 0px 0px 4px 4px;
  border-width: 0px 1px 1px 1px;
  display: flex;
  justify-content: flex-end; // 오른쪽 정렬
  opacity: 0px;
  background: #ffffff;
`;
